/* stylelint-disable property-no-vendor-prefix */
@import "variables";
@import "mixins";

html {
  font-size: 16px;

  @include respond(xs) {
    font-size: 11px;
  }

  @include respond(sm, md) {
    font-size: 12px;
  }
}

.content-text {
  font-family: $font-family-sans-serif;
}

.tooltip .tooltip-inner {
  color: $light;
  background-color: $secondary;
  font-family: $font-family-sans-serif;
}

.tooltip .arrow::before {
  border-top-color: $secondary;
}

.tooltip.show {
  opacity: 1;
}

h1,
h2 {
  font-family: $font-family-montserrat-light;
  color: $red;
}

h5 {
  line-height: 1.8rem;
}

h6 {
  font-family: $font-family-montserrat;
  font-size: 1.2rem;
  color: $blue-gray;
  letter-spacing: 2.16px;

  @include respond(xs,sm) {
    font-size: 1.5rem;
    text-align: center;
  }
}

input::placeholder {
  font-size: .9rem;
  color: $gray-400 !important;
}

strong {
  font-family: $font-family-montserrat-bold;
}
