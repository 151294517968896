@import "variables";
@import "mixins";

.list-wrapper {
  overflow: hidden;
  border-radius: 5px;

  .table {
    thead {
      background-color: $secondary;
      color: $light;
      height: 60px !important;

      @include respond(sm,md) {
        height: 50px !important;
      }

      .column-header {
        div {
          font-size: 1rem;
        }
      }
    }

    .item-row {
      .action-link.btn {
        height: 22px;
        width: 60px;
        font-size: 12.5px;
      }
    }
  }

  .page-options-size {
    font-size: 15px;

    .dropdown {
      padding: 0 2px;
    }

    .dropdown-toggle:hover {
      color: $btn-link-hover-color !important;
    }
  }

  .page-options-pagination {
    .page-link:hover,
    .page-link {
      color: $secondary;
      background-color: $light;
      box-shadow: unset !important;
    }

    .page-item.active,
    .page-item:active {
      .page-link {
        background-color: $primary !important;
        color: $white !important;
      }
    }

    .page-item.disabled {
      .page-link {
        color: $gray-300 !important;
      }
    }
  }

  .mobile-data {
    hr: {
      color: $secondary !important;
    }
  }
}
