@import "variables";

.datepicker-container {
  font-family: $font-family-sans-serif;

  .datepicker-icon {
    color: $secondary;
    cursor: pointer;
  }

  .ngb-dp-weekday {
    color: $dark;
  }
}
