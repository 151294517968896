@import "variables";
@import "mixins";

.btn-primary,
.btn-outline-primary {
  padding: 4px 10px;
}

.btn-search {
  padding: 4px 50px;
}

.btn-group {
  padding: 4px 30px;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  color: $white !important;
}

.btn-outline-primary {
  color: $primary !important;

  &:hover,
  &:focus-visible {
    color: $white !important;
  }
}

.btn-info {
  color: $white !important;
}

.btn-purple {
  border: unset;
  background: $purple !important;
  color: $white !important;
  height: 48px;
  width: 175px;
  font-size: 16px;
}
